import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CryptageKeysService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + `/api/CryptageKeys/listcryptageKeys`);
  }
  addCryptageKeys(user: any): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/CryptageKeys/add', user, { responseType: 'text' });
  }
  deleteCryptageKeys(id: number): Observable<any> {
    console.log("delete get id : " + id)
    return this.http.delete(environment.apiUrl + '/api/CryptageKeys/delete/' + id, { responseType: 'text' });
  }

  findCryptageKeysById(id: number): Observable<any> {
    console.log("findCryptageKeysById  : " + id)
    return this.http.get<any[]>(environment.apiUrl + `/api/CryptageKeys/find/` + id);
  }

  editCryptageKeys(user: any): Observable<any> {
    return this.http.put(environment.apiUrl + '/api/CryptageKeys/update', user, { responseType: 'text' });
  }
  genererCleCryptageRsa(password: String, token): Observable<string> {
    return this.http.post(environment.apiUrl + '/api/CryptageKeys/genererCleCryptage/' + token, { "password": password }, { responseType: 'text' });
  }

  envoyermaildecrypter(id): Observable<string> {
    return this.http.get(environment.apiUrl + '/api/CryptageKeys/envoyermaildecrypter/' + id, { responseType: 'text' });
  }
  envoyermaildecryptercomplement(id): Observable<string> {
    return this.http.get(environment.apiUrl + '/api/CryptageKeys/envoyermaildecryptercomplement/' + id, { responseType: 'text' });
  }
  privatkeydecrypt(password: String, token): Observable<string> {
    return this.http.post(environment.apiUrl + '/api/CryptageKeys/privatkeydecrypt/' + token, { "password": password }, { responseType: 'text' });

  }

  findConsultationbytokencryptagekey(token) {
    return this.http.get<any>(environment.apiUrl + `/api/CryptageKeys/Consultation/` + token);
  }
  findCryptagekeybytoken(token) {
    console.log("findCryptagekeybytoken : "+token)
    return this.http.get<any[]>(environment.apiUrl + `/api/CryptageKeys/findCryptagekeybytoken/` + token);
  }

  renvoyerMail(id): Observable<string> {
    return this.http.get(environment.apiUrl + `/api/CryptageKeys/renvoyerMail/` + id, { responseType: 'text' });
  }
  getkeyconsultation(id){
    return this.http.get(environment.apiUrl + `/api/CryptageKeys/keyconsultation/` + id);
  }
  editkeysconnsultation(idconsulation,idkey,etat){
    return this.http.get(environment.apiUrl + `/api/CryptageKeys/activation/res?idConsul=` + idconsulation+"&idKey="+idkey+"&etat="+etat, { responseType: 'text' });
  }
}
